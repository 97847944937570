import React,{useState} from 'react';

export default function Ipcard(props) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div  onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)} className={`flex flex-col 2xl:gap-5  lg:gap-2 2xl:px-6  lg:px-3 py-1 bg-[#0B294A]/30 border-2 border-[#E6FCFF]/20  2xl:rounded-[2.5rem] 2xl:w-[17.3rem] 2xl:h-[10rem]  lg:w-[12rem] lg:h-[7.5rem] w-[9rem] h-[5rem] rounded-[1rem] lg:rounded-[2rem]
     items-center justify-center hover:bg-[#0C2E52] px-1  ${
        isHovered ? '' : 'custom-shadow-ip'
      }`}>
        <div className='flex xl:gap-3  lg:gap-2 gap-[0.3rem] items-center h-[4.68rem]'>
            <img className="2xl:w-[4.68rem] 2xl:h-[4.68rem]  lg:w-[3.5rem] lg:h-[3.5rem] w-[2.2rem] h-[2.2rem]" src={props.logo} alt={props.name}></img>
            <p className='2xl:w-[8.25rem] xl:w-[8rem] font-opensans font-bold 2xl:text-[0.675rem] md:text-[0.51rem] text-[0.4rem] text-[#FBFBFB] text-left'>{props.name}</p>
        </div>
        <div className='font-opensans font-normal 2xl:text-[0.675rem]  md:text-[0.51rem] text-[0.4rem] text-white text-center'>{props.domain}</div>
    </div>
  )
}
