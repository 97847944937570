import React from 'react'
import { BrowserRouter, Route,Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Aboutus from './Pages/Aboutus';
import Ourip from './Pages/Ourip';
import Careers from './Pages/Careers';
import Hardware from './Pages/Hardware';
import Software from './Pages/Software'
import Labs from './Pages/Labs';
import Publications from "./Pages/Publications";
import News from './Pages/News';
import Contactus from './Pages/Contactus';

export default function App() {
  return <BrowserRouter>
  <Routes>
    <Route path="/" Component={Homepage}/>
    <Route path="/Aboutus" Component={Aboutus}/>
    <Route path="/Ourip" Component={Ourip}/>
    <Route path="/Hardware" Component={Hardware}/>
    <Route path="/Software" Component={Software}/>
    <Route path="/Labs" Component={Labs}/>
    <Route path="/Publications" Component={Publications}/>
    <Route path="/Careers" Component={Careers}/>
    <Route path="/Contactus" Component={Contactus}/>
    <Route path="/News" Component={News}/>

  </Routes>
  </BrowserRouter>
}

