export const newsdata=[
    {
        id:1,
        name:"India Today about the Qulabs",
        img:require("../../src/Assets/images/IndiaToday.png"),
        content:"Founded by Nixon Patel, who is also its CEO and and adjunct professor at IIT Hyderabad. Qulabz is currently working on quantum communication technologies. These include quantum random number generators touted to be more secure than the OTPs today and a quantum network simulator which candesign a secure communication link end to end The third product is a protfolio optimising toool for financial investments using quantum techniques and Artificial Intelligence.",
        link:""
    }
,
{
    id:2,
    name:"Qulabs visit to IIT Roorkee",
    img:require("../../src/Assets/images/QulabsRoorkee.jpeg"),
    content:"Glimpses of the Qulabs team visit the IIT Roorkee campus on June 2, 2023, to demonstrate quantum memory proof of concept developed by Qulabs. The PoC is a tabletop version of the atomic vapor-based EIT setup. Team Qulabs also presented its vision of the development of long-distance Quantum Communication and efforts to develop the building blocks of the quantum network.",
    link:require("../../src/Assets/News/roorkeenews.png")
},  
{
    id:3,
    name:"Qulabs-CQN collaboration",
    img:require("../../src/Assets/images/QulabsCQN.jpeg"),
    content:"Last week, India decided to join in this global effort in a big way, by setting up a Rs 6,000 crore National Mission on Quantum Technologies and Applications! We are here to share some more good news of India's advancement in quantum mission. Qulabs becomes the first Indian organization to be included as Gold partner with the 'Centre of Quantum Networks' (CQN). The 'Centre of Quantum Networks' in USA will play a pivotal role in developing the quantum internet like that of ARPANET for today's internet.",
    link:require("../../src/Assets/News/QulabsCQN.png")
},
{
    id:4,
    name:"IIT Tirupati Visit",
    img:require("../../src/Assets/images/Tirupati.jpg"),
    content:"Meeting with IIT Tirupati Director Prof. K N Satyanarayan and Board members",
    link:require("../../src/Assets/News/IIT tirupati visit.pdf")
},
{
    id:5,
    name:"DRDO Satheesh Reddy Visit",
    img:require("../../src/Assets/images/DRDO.jpg"),
    content:"Dr. G Satheesh Reddy DRDO, Scientist Adviser to Raksha Mantri (the minister of Defence) Govt of India with Qulabs Team",
    link:""
},
{
    id:6,
    name:"Quantum Technologies Director Venkata Rama Raju Visit",
    img:require("../../src/Assets/images/QuantumTech.jpg"),
    content:"Ventaka Rama Raju Chelle, Director of Quantum technologies, Govt. of India visited the Qulabs office to discuss on QKD, QPC QRNG",
    link:""
},
{
    id:7,
    name:"Wing Commander Satyam Kushwaha visit",
    img:require("../../src/Assets/images/Wingcommander.jpg"),
    content:"Wing commander Satyam Kushwaha shares his ideas with the Qulabs team.",
    link:require("../../src/Assets/News/wing commander.pdf")
}

];