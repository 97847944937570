import React, { useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Banner from "../Components/Banner";
import { profiledata, advisor } from "../Data/Profiledata";
import Profilecard from "../Components/Profilecard";
import "./../App.css";
import Advisors from "../Components/Advisors";

export default function Aboutus() {
  
  return (
    <div className="w-full bg-[#051525] relative">
      <Header id={2} />
      <Banner
        mainBanner={
          <div className="relative h-[95vh] flex items-center justify-center">
            <img
              className="w-full h-[95vh] object-cover  mix-blend-difference"
              src={require("../../src/Assets/images/Team.jpg")}
            ></img>
            <div className="w-[90%] flex flex-col items-center justify-center transformBottom bottom-14 sm:bottom-5">
              <div
                className="hidden md:block font-opensans font-bold text-[6rem] text-left "
                style={{
                  WebkitTextStroke: "6px rgba(217,217,217,0.4) ",
                  color: "transparent",
                }}
              >
                Our Team
              </div>
              <div
                className="block md:hidden font-opensans font-bold text-[3rem] text-left "
                style={{
                  WebkitTextStroke: "3px rgba(217,217,217,0.4) ",
                  color: "transparent",
                }}
              >
                Our Team
              </div>
              <p className="font-opensans font-medium md:text-sm text-[0.6rem] text-white  text-center">
                We are a curious bunch of individuals who hail from diverse
                backgrounds, working together towards achieving the Qulabs
                mission.
              </p>
            </div>
          </div>
        }
      />
      <div className="flex flex-col items-center justify-center gap-6  px-19 py-12">
        <p className="font-opensans font-semibold 2xl:text-3xl md:text-2xl text-md text-white">
          Creating Intelligent Solutions With Quantum
        </p>
        <div className="md:w-[49.75rem] w-[80%] md:py-6 py-2 flex flex-col items-center justify-center">

          <p className="text-justify  font-opensans font-semibold 2xl:text-sm md:text-[0.7rem] text-[0.4rem] text-[#E6FCFF] py-1">
            We have a vision to create a quantum future by using quantum
            principles for engineering solutions to extremely complex problems
            in computing, communications, sensing, chemistry, cryptography,
            imaging and mechanics. Our work is progressing in the direction to
            provide the world its first Quantum Internet and as a result create
            a completely secure internet and communication at immense speed and
            security.
          </p>
          <p className=" text-justify font-opensans font-semibold 2xl:text-sm md:text-[0.7rem]  text-[0.4rem] text-[#E6FCFF] py-1">
            We have put together a world class team of research quantum and
            networking scientists to build this new paradigm of secure
            communications for commercial customers. Our business goal is to
            deliver reliable, high quality, cost-effective, efficient, scalable
            and absolutely secure quantum communication solutions to a global
            marketplace through our quantum Internet platform and software stack
            by applying latest state-of-art technology advancements with both
            private and public participation that will provide most secure, more
            accessible, affordable, and transparent communication platform to
            consumers, governments, businesses and start-ups.
          </p>
          <p className=" text-justify font-opensans font-semibold 2xl:text-sm md:text-[0.7rem]  text-[0.4rem] text-[#E6FCFF] py-1">
            Qulabs has been named as one of first and among the top 8 start- ups
            by Analytics India Magazine, aiming to bring India in the list of
            few countries with an edge in this emerging field which will have a
            greater advantage in garnering multifold economic growth and
            dominant leadership to lead the quantum computing race in India.
          </p>
          <p className=" text-justify font-opensans font-semibold  2xl:text-sm md:text-[0.7rem]  text-[0.4rem] text-[#E6FCFF] py-1">
            Our team consists of bright, energetic and talented people who have
            a passion for making a difference in the world by helping
            underserved communities.
          </p>
        </div>
      </div>
      <div className=" w-full 2xl:h-[31.24rem] md:h-[28rem] h-[40rem] customGradient flex items-center justify-center">
        <div className="sm:w-[80%] w-[100%] flex sm:flex-row flex-col items-center justify-center md:gap-60 gap-10 ">
        
        <div className="flex flex-col  2xl:left-[15rem] lg:left-[8rem] ">
          <div className="flex items-center md:gap-10 gap-3 py-2">
            <img
              className="2xl:w-[3.75rem] 2xl:h-[3.75rem] md:w-[3rem] md:h-[3rem] w-[1.5rem] h-[1.5rem]"
              src={require("../../src/Assets/images/QuantumEducation.png")}
            ></img>
            <div className="flex flex-col gap-1">
              <p className="font-opensans font-semibold 2xl:text-[1.2rem] md:text-[1rem] text-[0.8rem] text-white text-left">
                Our Mission
              </p>
              <p className="w-[9rem] md:w-[14.1rem] font-opensans text-[#E6FCFF] font-semibold 2xl:text-[0.65rem] md:text-[0.61rem] text-[0.5rem] text-justify">
                Our mission is to to develop scalable prototypes of quantum
                technologies and an inherently safe quantum internet based on
                the fundamental theories in quantum mechanics.
              </p>
            </div>
          </div>
          <div className="flex items-center md:gap-10 gap-3 py-2">
            <img
              className="2xl:w-[3.75rem] 2xl:h-[3.75rem] md:w-[3rem] md:h-[3rem] w-[1.5rem] h-[1.5rem]"
              src={require("../../src/Assets/images/Vision.png")}
            ></img>
            <div className="flex flex-col gap-1">
              <p className="font-opensans font-semibold 2xl:text-[1.2rem] md:text-[1rem] text-[0.6rem]  text-white text-left">
                Our Vision
              </p>
              <p className="w-[9rem] md:w-[14.1rem] font-opensans text-[#E6FCFF] font-semibold 2xl:text-[0.65rem] md:text-[0.61rem] text-[0.4rem] text-justify">
                Our Vision is to provide the world its first Quantum
                Communication and Quantum Computing based solution by creating a
                completely secure internet and communication at immense speed
                and security.
              </p>
            </div>
          </div>
          <div className="flex items-center md:gap-10 gap-3 py-2">
            <img
              className="2xl:w-[3.75rem] 2xl:h-[3.75rem] md:w-[3rem] md:h-[3rem] w-[1.5rem] h-[1.5rem]"
              src={require("../../src/Assets/images/Aspiration.png")}
            ></img>
            <div className="flex flex-col gap-1">
              <p className="font-opensans font-semibold 2xl:text-[1.2rem] md:text-[1rem] text-[0.6rem] text-white text-left">
                Our Aspiration
              </p>
              <p className="w-[9rem] md:w-[14.1rem] font-opensans text-[#E6FCFF] font-semibold 2xl:text-[0.65rem] md:text-[0.61rem] text-[0.4rem] text-justify">
                Our aspiration is not merely to keep pace with progress but to
                lead the way. "Transforming Visions into Quantum Realities"
                encapsulates our commitment to turning theoretical possibilities
                into practical solutions that redefine the technological
                landscape.
              </p>
            </div>
          </div>
        </div>
        <img
          className=" 2xl:w-[45.9rem] md:w-[35rem] 2xl:h-[31.24rem] md:h-[28rem] w-[100%] h-[18rem]  "
          src={require("../../src/Assets/images/MissionVision.png")}
        ></img>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center md:px-20 md:py-10 px-5 py-7">
        <p className="font-opensans font-extrabold 2xl:text-3xl md:text-2xl  text-[#E7EAEE]">
          Team Members
        </p>
        <div className="flex flex-wrap max-w-full 2xl:gap-5 md:gap-3 gap-1 mx-auto items-center justify-center md:px-20 md:py-10 px-3 py-7 cursor-pointer">
          {profiledata.map((item) => (
            <div key={item.id} className="flex-item ">
              <Profilecard
                img={item.img}
                name={item.name}
                title={item.title}
                content={item.content}
                linkedin={item.linkedin}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center md:px-20 md:py-10 px-5 py-7">
        <p className="font-opensans font-extrabold 2xl:text-3xl md:text-2xl  text-[#E7EAEE]">
          Meet The Advisors
        </p>
        <div className="flex flex-wrap max-w-full  2xl:gap-5 md:gap-3 gap-1 mx-auto justify-center md:px-20 md:py-10 px-3 py-7 cursor-pointer">
          {advisor.map((item) => (
            <div
              key={item.id}
              className="flex-item "
            >
              <Advisors
                name={item.name}
                title={item.title}
                content={item.content}
                linkedin={item.linkedin}
              />
            </div>
          ))}
          
        </div>
      </div>
      <Footer showpage={true} />
    </div>
  );
}
