import React, { useState } from "react";
import { LuArrowRight } from "react-icons/lu";

export default function Newscard(props) {
  const [mainblock, setMainBlock] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [Read, setRead] = useState(false);
  const handleReadMoreClick = (url, event) => {
    setRead(true);
    props.onReadMoreClick({ id: props.id });
    event.stopPropagation();
    if (url) {
      window.open(url, "_blank");
    }
  };
  return (
    <div
      onMouseOver={() => setMainBlock(true)}
      onMouseLeave={() => setMainBlock(false)}
      className="2xl:w-[40rem] 2xl:h-[30rem] md:w-[35rem] md:h-[25rem] bg-[#D9FBFF]/20 hover:bg-[#D9FBFF]/50 flex flex-col items-center justify-center gap-4 rounded-[3rem]"
    >
      <img
        src={props.img}
        className="2xl:w-[25rem] 2xl:h-[15rem] md:w-[20rem] md:h-[10rem] 2xl:rounded-3xl md:rounded-2xl mt-5"
      ></img>
      <div className="w-full flex flex-col items-center justify-center gap-3 px-10 py-3">
        <p
          className={`text-white/80 font-opensans font-extrabold 2xl:text-lg md:text-base ${
            mainblock ? "text-white" : ""
          }`}
        >
          {props.name}
        </p>
        <p
          className={`text-white/60 font-opensans font-bold 2xl:text-[0.6rem] md:text-[0.5rem] text-justify 2xl:h-[4rem] md:h-[3rem] px-2 ${
            mainblock ? "text-white" : ""
          }`}
        >
          {props.content}
        </p>
        <div className="w-full flex items-center justify-start">
          <div
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className={`flex items-center justify-center gap-1 2xl:rounded-2xl md:rounded-xl px-2 py-1 cursor-pointer ${
              isHovered ? "bg-[#051525]" : ""
            }`}
          >
            {props.link && (
              <div
                onMouseOver={() => setRead(true)}
                onMouseLeave={() => setRead(false)}
                onClick={(event) => handleReadMoreClick(props.link, event)}
                className="flex items-center justify-center gap-1 rounded-lg px-1 cursor-pointer"
              >
                <div className="bg-[#051525] flex items-center justify-center 2xl:w-[2.2rem] 2xl:h-[2.1rem] md:w-[2rem] md:h-[1.9rem] rounded-full">
                  <LuArrowRight color="white" size={20} />
                </div>
                <p className="font-opensans font-bold 2xl:text-[0.8rem] md:text-[0.6rem] text-left text-white">
                  Read More...
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
