import React, { useState } from "react";
import { LuArrowRight } from "react-icons/lu";

export default function Publicationcard(props) {
  const [mainblock, setMainBlock] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [Read, setRead] = useState(false);
  const handleReadMoreClick = (url,event) => {
    setRead(true);
    // Send data to Logoscontainer
    props.onReadMoreClick({ id: props.id });
    event.stopPropagation();
        if (url) {
          window.open(url, '_blank');
        }  // Assuming you have a 'name' prop in Logocard
  };
  return (
    <div
      onMouseOver={() => setMainBlock(true)}
      onMouseLeave={() => setMainBlock(false)}
      className="w-[60rem] h-[15rem] bg-[#D9FBFF]/50 hover:bg-[#D9FBFF]/20 flex flex-col items-center justify-center gap-2 rounded-[3rem] px-4 py-3"
    >
        <div className="w-full flex items-center justify-between">
      <div className="w-full h-full flex flex-col items-start justify-between gap-3 px-10 py-3 ">
        <div className=" flex flex-col items-start justify-center gap-2 mt-2">
        <p
          className={`text-black font-opensans font-extrabold text-lg  ${
            mainblock ? "text-white" : ""
          }`}
        >
          {props.name}
        </p>
        <p
          className={`text-white/60 font-opensans font-bold text-[0.6rem] text-justify h-[4rem]  ${
            mainblock ? "text-white" : ""
          }`}
        >
          {props.content}
        </p>
        </div>
        <div className="w-full flex items-center justify-start">
        <div
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className={`flex items-center justify-center gap-1  rounded-2xl px-2 py-1 cursor-pointer ${
            isHovered ? "bg-[#051525]" : ""
          }`}
        >
          <div
            onMouseOver={() => setRead(true)}
            onMouseLeave={() => setRead(false)}
            onClick={(event) => handleReadMoreClick(props.link, event)}
            className="flex  items-center justify-center gap-1  rounded-lg  cursor-pointer"
          >
            <div className="bg-[#051525] flex items-center justify-center w-[2.2rem] h-[2.1rem]  rounded-full">
              <LuArrowRight color="white" size={20} />
            </div>
            <p className="font-opensans font-bold text-[0.8rem]  text-left text-white">
              Read More...
            </p>
          </div>
        </div>
      </div>
      </div>
      </div>

    </div>
  );
}
